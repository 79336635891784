/* src/components/Contact.css */

.contact-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically on smaller screens */
  max-width: 100%; /* Maximum width of the container */
  margin: 0 auto; /* Center the container */
  padding: 20px; /* Padding around the container */
  gap: 20px; /* Space between elements */
  text-align: center; /* Center text */
}

@media (min-width: 768px) {
  .contact-container {
    flex-direction: row; /* Side by side on larger screens */
  }
}

.contact-details {
  flex: 1; /* Allow this section to take available space */
  background-color: #f9f9f9; /* Light background color */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding inside the details box */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.contact-form {
  flex: 1; /* Allow this section to take available space */
  background-color: #fff; /* White background for the form */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding inside the form box */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

h1 {
  text-align: center; /* Center the heading */
  color: #333; /* Dark text color */
  margin-bottom: 10px; /* Space below the heading */
}

.details {
  margin-top: 10px; /* Space above the details section */
}

.form-group {
  margin-bottom: 15px; /* Space between form groups */
}

label {
  margin-bottom: 5px; /* Space below the label */
  font-weight: bold; /* Bold text for labels */
  color: #555; /* Dark gray color for labels */
}

input,
textarea {
  padding: 10px; /* Padding inside input and textarea */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 4px; /* Rounded corners */
  font-size: 16px; /* Font size for inputs */
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding and border in width */
}

input:focus,
textarea:focus {
  border-color: #007bff; /* Change border color on focus */
  outline: none; /* Remove default outline */
}

textarea {
  resize: vertical; /* Allow vertical resizing only */
  min-height: 100px; /* Minimum height for textarea */
}

.submit-button {
  padding: 10px; /* Padding inside the button */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  background-color: #007bff; /* Blue background */
  color: white; /* White text color */
  font-size: 16px; /* Font size for button */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.submit-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}