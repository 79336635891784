.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

nav {
  background-color: #282c34;
  padding: 10px;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 15px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

.company-name {
  color: #ffffff; 
}

/* src/App.css */

/* App.css or a separate CSS file */


